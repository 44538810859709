<template>
  <div class="row">
    <div class="col-12">
        <div class="card card-body">
            <div class="row">
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="">
                        بحث
                      </label>
                      <input type="text"
                        class="form-control" v-model="q" placeholder="الاسم٫ الهاتف٫ رقم الطلب٫رقم تأكيد الحجز" @keyup="getOrders()">
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="">
                        تاريخ الدخول
                      </label>
                      <input type="date"
                        class="form-control" v-model="date" @change="getOrders()">
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="form-group">
                      <label for="">
                        حالة الطلب
                      </label>
                      <select class="form-control" v-model="status" @change="getOrders()">
                        <option :value="null">-- الكل --</option>
                        <option :value="0">في الانتظار</option>
                        <option :value="1">تم التأكيد</option>
                        <option :value="2">في انتظار الالغاء</option>
                        <option :value="3">تم الالغاء</option>
                      </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-body">
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" style="width: 50px; height; 50px;" alt="">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                           # 
                        </th>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الهاتف
                        </th>
                        <th>
                            الفندق
                        </th>
                        <th>
                            تاريخ الدخول
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            المبلغ
                        </th>
                        <th>
                            العمولة
                        </th>
                        <th>
                            الغاء الحجز
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders" :key="order._id">
                            <td>
                                {{ order.id }}
                            </td>
                            <td>
                                {{ order.name }}
                            </td>
                            <td>
                                {{ order.phone }}
                            </td>
                            <td>
                                {{ order.hotel.title }}
                            </td>
                            <td>
                                {{ order.days[0] }} ({{ order.days.length-1 }} يوم)
                            </td>
                            <td>
                                <span class="btn btn-sm btn-warning" style="border-radius: 0px;" v-if="order.status == 0">في الانتظار</span>
                                <span class="btn btn-sm btn-success" style="border-radius: 0px;" v-if="order.status == 1">تم التأكيد</span>
                                <span class="btn btn-sm btn-danger" style="border-radius: 0px;" v-if="order.status == 2">في انتظار الالغاء</span>
                                <span class="btn btn-sm btn-secondary" style="border-radius: 0px;" v-if="order.status == 3">ملغي</span>
                            </td>
                            <td>
                                {{ order.total_reseller }}
                            </td>
                            <td>
                                {{ order.extra }}
                            </td>
                            <td>
                                <button class="btn text-danger btn-sm" v-if="order.status == 0 || order.status == 1" @click="current = order" v-b-modal.modal-1>
                                    <i class="fa fa-trash"></i>
                                    الغاء الحجز
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12 g">
                <button class="btn btn-danger btn-sm" v-if="page > 0" @click="page--; getOrders()"><i class='fa fa-arrow-right'></i> الصفحة السابقة</button>
                &nbsp;
                {{ page + 1 }} <span class="text-muted">({{ orders.length }})</span>
                &nbsp;
                <button class="btn btn-success btn-sm" v-if="orders.length > 0" @click="page++; getOrders()">الصفحة التالية <i class='fa fa-arrow-left'></i></button>
            </div>
        </div>
    </div>
     <b-modal id="modal-1" size="sm" title="الغاء الطلب" hide-footer>
        <h4>
            يرجى العلم ان الغاء الحجز بعد تأكيده من الفندق قد يترتب عليه خصم رسوم من رصيدك.
        </h4>
        <div class="form-group">
          <label for="">سبب الالغاء</label>
          <textarea class="form-control" rows="3" placeholder="اكتب هنا..." v-model="reason"></textarea>
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-danger" @click="cancel()">
                الغاء الحجز الآن
            </button>
        </div>
     </b-modal>
  </div>
</template>

<script>
import { VBModal, BModal, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    components: {
        BModal,BFormCheckbox,
    },
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            orders: [],
            loading: false,
            page: 0,
            current: {},
            reason: "",
            q: null,
            date: null,
            status: null,
        }
    },
    created(){
        var g = this;
        this.getOrders();
    },
    methods: {
        getOrders(){
            var g = this;
            g.loading = true;
            $.post(api + '/reseller/orders/index', {
                jwt: this.jwt,
                page: this.page,
                date: this.date,
                q: this.q,
                status: this.status
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.orders = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        },
        cancel(){
            var g = this;
            $.post(api + '/reseller/orders/cancel', {
                jwt: this.jwt,
                id: this.current._id,
                reason: this.reason
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    $("#modal-1___BV_modal_header_ > button").click()
                    g.getOrders()
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
        }
    }
}
</script>

<style>

</style>